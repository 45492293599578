import _ from 'lodash';

import Vue from 'vue'
import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

// Install BootstrapVue
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// This imports all the layout components such as <b-container>, <b-row>, <b-col>:
import { LayoutPlugin } from 'bootstrap-vue'
Vue.use(LayoutPlugin)

// This imports <b-modal> as well as the v-b-modal directive as a plugin:
import { ModalPlugin } from 'bootstrap-vue'
Vue.use(ModalPlugin)

// This imports <b-card> along with all the <b-card-*> sub-components as a plugin:
import { CardPlugin } 				from 'bootstrap-vue'
Vue.use(CardPlugin)

//// This imports directive v-b-scrollspy as a plugin:
//import { VBScrollspyPlugin } 		from 'bootstrap-vue'
//Vue.use(VBScrollspyPlugin)

// This imports the table plugins
import { TablePlugin } from 'bootstrap-vue'
Vue.use(TablePlugin)

//Icons
//import Unicon from 'vue-unicons'
//import { uniLayerGroupMonochrome, uniLocationArrow, uniTrash, uniLineAlt, uniVectorSquare } from 'vue-unicons/src/icons'

//Unicon.add([uniLayerGroupMonochrome, uniLocationArrow, uniTrash, uniLineAlt, uniVectorSquare])
//Vue.use(Unicon)




// OL specific
import 'ol/ol.css';
import {Map, View} 					from 'ol';
import TileLayer 					from 'ol/layer/Tile';
import OSM 							from 'ol/source/OSM';
import {createStringXY} 			from 'ol/coordinate.js';
import {ScaleLine} 					from 'ol/control'; 
import Control 						from 'ol/control/Control';
import Projection 					from 'ol/proj/Projection.js';
import MousePosition 				from 'ol/control/MousePosition.js';
import Rotate 						from 'ol/control/Rotate';
import WKT 							from 'ol/format/WKT';

//My components
//import LayerInfo 			from './layerinfo.vue';
import LayersControl 		from './layerscontrol.vue';
import TickbarHor 			from './ticbar_hor.vue';
import TickbarVert 			from './ticbar_vert.vue';
import FeatureInfo 			from './feature_info.vue';
import ModalSearchLocation 	from './modal_search_location.vue';
import ModalSearchLayer 	from './modal_search_layer.vue';
import ModalEditAttributes 	from './modal_edit_attributes.vue';
import ModalSignIn 			from './modal_sign_in.vue';
import ModalManageLayers 	from './modal_manage_layers.vue';
import ModalPrintView 		from './modal_print_view.vue';
import ModalTransact 		from './modal_transact.vue';
import DrawingTools 		from './drawing_tools.vue';
import ArchisTool 			from './archis_tool.vue';
import ModalStyling			from './modal_styling.vue';

import ModalQuestion        from './modal_question.vue';
import ModalAskfilelocation from './modal_askfilelocation.vue';
import ModalSelect 			from './modal_select.vue';


//import AokMap 				from './aokmap.vue';

import {createLayer}		from './aokFunctions';
import {setMapTics}			from './setMapTics';

import InteractionDelete    from './InteractionDelete.js';



//projection specific
import proj4 from 'proj4';
import {register} from 'ol/proj/proj4';
import {get as getProjection} from 'ol/proj';

//15 december 2022: Projectie string 28992 uitgebreid met '+towgs84=565.417,50.3319,465.552,-0.398957,0.343988,-1.8774,4.0725 ' om satellietbeeld in GoogleMapsCompatible EPSG:3857 goed te leggen!

//proj4.defs("EPSG:28992", "+proj=sterea +lat_0=52.15616055555555 +lon_0=5.38763888888889 +k=0.9999079 +x_0=155000 +y_0=463000 +ellps=bessel +units=m +no_defs");
proj4.defs("EPSG:28992", "+proj=sterea +lat_0=52.15616055555555 +lon_0=5.38763888888889 +k=0.9999079 +x_0=155000 +y_0=463000 +ellps=bessel +towgs84=565.417,50.3319,465.552,-0.398957,0.343988,-1.8774,4.0725 +units=m +no_defs");

proj4.defs('http://www.opengis.net/gml/srs/epsg.xml#28992', proj4.defs('EPSG:28992'));
proj4.defs('urn:ogc:def:crs:EPSG::28992', proj4.defs('EPSG:28992'));
proj4.defs('epsg:28992', proj4.defs('EPSG:28992'));
register(proj4);

Vue.use( require('vue-shortkey') )

//our App


var data = { 
	modalLayerInfo_show				: false,
	modalLayerInfo_layer			: null,
	userdata						: null,
	isEditing						: false,
	isPrintView						: false,
	printData						: {} ,
	initExtent						: {} ,
	mapHeight						: 500,
	mapWidth						: 500,
	scale							: 1,
	mapStyleClassDefault			: "h-100 w-100" ,
	scaleLineDefault				: "scaleLineDefault" ,
	mapStyleClassPrint				: 'printStyle',
	scaleLinePrint					: "scaleLinePrint" ,
	

}

var app = new Vue({
  el: 			'#app',
  components: {  ModalQuestion, ModalAskfilelocation, ModalSelect, ModalStyling, LayersControl, ModalSearchLocation, ModalSearchLayer, ArchisTool, DrawingTools, ModalEditAttributes, ModalSignIn, ModalManageLayers, FeatureInfo, ModalPrintView, ModalTransact, TickbarHor, TickbarVert },
  data: 		data,
	methods: {
		startEdit( layer ) {
			this.$refs.drawingTools.registerLayerForEdit( layer );
			this.isEditing = true
			layer.get('startEdit')();
		},
		stopEdit( layer ) {
			this.$refs.drawingTools.unregisterLayerForEdit( layer );
			this.isEditing = false
			layer.get('stopEdit')();
		},
		signout: function () {
			Object.assign(this.$data, this.$options.data.call(this)); //reset data to inital values
			//remove layers
			let collection;
 			while ( (collection = map.getLayers()).getLength() ) collection.pop();
			this.$root.$refs.modalSignIn.signOut()
			
			fetch("getFilteredLayers.php?start=true")
    			.then( response => response.json())
    			.then( function ( layerArray ) {
    				layerArray.forEach( function( layerdata ) {
						map.addLayer( createLayer( layerdata ));
					})
				})
		},
 		zoomIn() {
			map.getView().setZoom( map.getView().getZoom() + 1 )
		},
 		zoomOut() {
			map.getView().setZoom( map.getView().getZoom() - 1 )
		},
 		copyMapXY() {
			var xy = document.getElementsByClassName('ol-my-mouse-position')[0].innerHTML
			this.$bvModal.msgBoxOk("Coördinaten:  " + xy )
		},
	}, 
	mounted: function () {
		if ( typeof isPrintView !== "undefined" && isPrintView  ) {
			this.isPrintView = true
			setMapSize() 
		}

	}


});

function setMapSize() {
 	if ( typeof isPrintView !== "undefined" && isPrintView  && printData ) {
		if ( ! printData.scale ) {
		 	printData.scale  = 1.0
		}
		document.documentElement.style.setProperty('--print-scale',  printData.scale)
		document.documentElement.style.setProperty('--print-width',  Math.round( mapWidth * printData.scale) + 'px');
		document.documentElement.style.setProperty('--print-height', Math.round( mapHeight* printData.scale) + 'px');
		if  ( typeof map.setSize !== 'undefined' ) {
			map.setSize( [ Math.round( mapWidth * printData.scale),  Math.round( mapHeight * printData.scale ) ])
			map.getView().fit( initExtent )
		}
	}
}

console.log('create map');

 fetch("https://www.aardeopdekaart.nl/errorlog.php?test=dit");

var boundsNL = [-285401.92, 22598.08, 595401.9199999999, 903401.9199999999];
var resolutionsNL =  [3440.64, 1720.32, 860.16, 430.08, 215.04, 107.52, 53.76, 26.88, 13.44, 6.72, 3.36, 1.68, 0.84, 0.42, 0.21, 0.105, 0.0525, 0.02525];

var controls = [ new ScaleLine({ 
		units	: "metric", 
		bar		: false, 
		minWidth: 140,
		target	: "scaleline"
	}) 
];
var projection = new Projection({code:'EPSG:28992', units:'m', extent: boundsNL});
var mousePositionControl = new MousePosition({
                                            coordinateFormat: createStringXY(0),
											className		: 'ol-my-mouse-position',
                                            target			: 'coordinates',
                                            projection		: projection,
                                            undefinedHTML	: '' });
controls.push( mousePositionControl );
controls.push( new Rotate({ autoHide: true } ) );

var crosshairElement = document.getElementById('crosshair')
controls.push( new Control({element: crosshairElement }))

window.map = new Map({
  	controls:               controls,
    layers:                 [ ],
    keyboardEventTarget:    document,
    target:                 'map',
    //pixelRatio:             typeof( printData ) !== "undefined" && printData.hires == "1" ? "2": "1",
    view: new View({
    	center:                 [143000, 500000],
        projection:             'EPSG:28992',
        resolutions:            resolutionsNL,
        zoom:                   3,
        constrainResolution:    true
    })
});

// Register delete features on press delete
map.addInteraction ( new InteractionDelete )

map.on('movestart', function(evt){ document.getElementById("crosshair").style.visibility = 'visible'; });
map.on('moveend', function(evt){ document.getElementById("crosshair").style.visibility = 'hidden'; });


map.set('app', app ); //register the root application in Map object

map.getLayers().on('change:length', function () {

	if ( ! app.$refs.layersControl )  {
		return
	}
	app.$refs.layersControl.$data.mapLayers_data =  [];
	let collection = map.getLayers();
	let length = collection.getLength();

	for( let i = 0; i < length ; i++){
		app.$refs.layersControl.$data.mapLayers_data[ i ] =  collection.item( length - i - 1 );
	}

});


var updateMousePositionFormat = function ( map, control ) {
		var zoom = map.getView().getZoom(); 
		if ( zoom < 10 ) {
			control.setCoordinateFormat( createStringXY(0) );
		} else { 
			control.setCoordinateFormat( createStringXY(2) );
		}
	}

map.getView().on(
			'change', 
			function () { updateMousePositionFormat( map, mousePositionControl); }  
);

console.log('create temp layer');
var tempLayer = createLayer({
	isServerLayer 	: false,
	groupid			: null,
	label			: "templayer",
	type		  	: "user",
	groupname	 	: null,
	description		: "none",
	name			: "templayer", 
	access			: "public",
	isSaveable		: false,
	isEditable		: false,
	userid			: "1",
	id				: "1",
	canbecomeowner	: false,
	style			: { strokeColor		: 'magenta' }
});
map.set('tempLayer', tempLayer);

console.log('create coord raster temp layer');
var tempLayer = createLayer({
	isServerLayer 	: false,
	groupid			: null,
	label			: "coordRasterLayer",
	type		  	: "user",
	groupname	 	: null,
	description		: "none",
	name			: "templayer", 
	access			: "public",
	isSaveable		: false,
	isEditable		: false,
	userid			: "1",
	id				: "1",
	canbecomeowner	: false,
	style			: {
		isLabeled		: true,
		strokeColor		: 'black',
		strokeWidth		: '1',
		placement		: 'line',
		textAlign		: 'center' 
	}
});

map.set('coordRasterLayer', tempLayer);


//https://stackoverflow.com/questions/40328932/javascript-es6-promise-for-loop/40329190
if (! isPrintView ) {
	fetch("getFilteredLayers.php?start=true")
    	.then( response => response.json())
    	.then( function ( layerArray ) {
    		layerArray.forEach( function( layerdata ) {
				map.addLayer( createLayer( layerdata ))
			})
		})
} else {
	printData.layers.forEach( function( layerdata ) {
		map.addLayer( createLayer( layerdata ))
		console.log( layerdata )
	})

	setMapSize()
    map.isLegend = false;
	setMapTics()
	map.getLayerGroup().once('change', setMapTics );
	map.getView().on('change', setMapTics );
	map.addControl( new Rotate({ autoHide: false } ) );
	document.title = "Aarde op de Kaart - PrintView";
}

