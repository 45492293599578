<!--https://vegibit.com/single-file-components-in-vuejs/-->
<template>
	<div>
    <b-modal 
			no-fade
			id			="modal-styling" 
			size		="xl" 
			v-model		="isShown"  
			v-bind:title=" getProperty('name')"
			@hide		= "saveStyle"
			style		="min-width: 40 rem;">
			<template #modal-title>
				<span v-if="editMode == 'layer'">
				 	{{ myObject.get('name') }}
					<b-badge v-if='isVector'> {{ myObject.getSource().getFeatures().length }} feature(s)</b-badge> 
					<b-badge>{{ getProperty('type') }}</b-badge>
					<b-badge>{{ getProperty('user') }}</b-badge>
				</span>
				<b-badge v-if="editMode == 'feature'"> {{ myObject.getGeometry().getType() }}</b-badge>
    		</template>
			<template #modal-footer>
				<b-button @click="isShown= false">close</b-button>
    		</template>
		<b-container>
		  <b-row>

 			<b-col v-if="getProperty('id') === '29164'" lg="7"> 
		   		<b-card>
 					<b-form-group label-for="layerTimeBPRange" sm label="tijd in BP">
						<b-form-input type="range" id="layerTimeBPRange" @change="updateWMSParams" v-model="myStyle.timeBP" min="0" max="40000" step="100"></b-form-input>
						<div class="mt-2">time BP: {{ myStyle.timeBP }}</div>
    				</b-form-group>
				</b-card>
 			</b-col> 
				
 			<b-col lg="3"> 
			<b-card sm v-if="getProperty('type') === 'wms'"> 
					<b-button variant			="outline-info" size			="sm" v-for			="(item, index) in wmsLayers"
  						v-bind:item		="item"
  						v-bind:index	="index"
  						v-bind:key		="item.id"
						@click			="showLegend(item)">
						{{item}}
					</b-button>

			</b-card >

			<b-card sm v-if=" editMode == 'layer'">
   	         	<b-button class='mt-1' variant="outline-info" size="sm" v-on:click="zoom">Zoom to extent</b-button>
   	         	<b-button class='mt-1' variant="outline-info" size="sm" v-on:click="reload">Reload</b-button>
				<b-button class='mt-1' variant="outline-info" size="sm" v-b-modal.modal-list-features title="lijst met features weergeven">List Features</b-button>
			</b-card>
			<b-card sm v-if=" isVector && editMode == 'layer' ">
				<b-button class='mt-1' variant="outline-secondary" size="sm" v-b-modal.modal-download-geojson-data title="Download GeoJSON data"><b-icon icon="download"></b-icon>GeoJSON</b-button>
				<b-button class='mt-1' variant="outline-secondary" size="sm" v-b-modal.modal-download-gml-data title="Download GML data"><b-icon icon="download"></b-icon>GML</b-button>
				<b-button class='mt-1' variant="outline-secondary" size="sm" v-b-modal.modal-download-shape-data title="Download ESRI-Shape data"><b-icon icon="download"></b-icon>Shape</b-button>
				<b-button class='mt-1' variant="outline-secondary" size="sm" v-b-modal.modal-download-gpx-data title="Download GPX data"><b-icon icon="download"></b-icon>GPX</b-button>
  			</b-card>
			<b-card sm v-if=" isVector && editMode == 'layer' ">
				<b-button class='mt-1' variant="outline-primary" size="sm"  @click="submitKlicUrl" title="Submit KLIC data"><b-icon icon="upload"></b-icon>KLIC</b-button>
				<b-button class='mt-1' variant="outline-primary" size="sm"  @click="submitBoreLog" title="Submit Boring data"><b-icon icon="upload"></b-icon>BoreLog</b-button>
				<b-button class='mt-1' variant="outline-primary" size="sm"  @click="submitGISData" title="Submit GIS data"><b-icon icon="upload"></b-icon>GeoJSON</b-button>
  			</b-card>
			<b-card sm >
				<b-button class='mt-1' v-if=" canBecomeOwner " variant="outline-warning" size="sm"  @click="becomeOwner" title="Become owner of this layer"><b-icon icon="sunrice"></b-icon>become owner</b-button>
				<b-button class='mt-1' v-if=" editMode == 'layer' " variant="outline-warning" size="sm"  @click="resetToDefaultLayerStyle" title="Reset styles this layer"><b-icon icon="sunrice"></b-icon>reset style</b-button>
				<b-button class='mt-1' v-if=" isEdited " variant="outline-warning" size="sm"  @click="resetFeaturesToDefaultStyle" title="Reset styles of features"><b-icon icon="sunrice"></b-icon>reset features styles</b-button>
  			</b-card>
			<b-card sm >
 				<b-form-group v-if="editMode == 'layer'" label-cols-sm="5" label-for="layerOpacitySpinButton" sm label="matheid">
					<b-form-spinbutton id="layerOpacitySpinButton" v-model="myStyle.layerOpacity" min="0" max="1" step="0.1"></b-form-spinbutton>
    			</b-form-group>
				<div v-if=" isVector ">
					<b-form-group  label-cols-sm="5"  sm label="punt grootte">
						<b-form-spinbutton id="pointRadiuSpinbut" v-model="myStyle.pointRadius" min="0" max="100"></b-form-spinbutton>
   					</b-form-group>
					<b-form-group  label-cols-sm="5"  sm label="hoek (graden)" >
						<b-form-spinbutton id="pointAngleSpinbut" v-model="myStyle.pointAngle" min="0" max="360" step="5" :disabled ="myStyle.pointType !== 'arrow'"></b-form-spinbutton>
   					</b-form-group>
 					<b-form-group sm label="punt type" >
						<b-form-select v-model="myStyle.pointType"  :options="pointTypeOptions" size="sm"> </b-form-select>
    				</b-form-group>
				</div>
  			</b-card>
 		   </b-col>

 		   <b-col lg="3">
			<b-card v-if='isVector' sm >
 				<b-form-group class="d-none d-lg-block" label="label kleur" >
					<b-form-radio-group 
						:key			= "update"
						sm
						stacked
						button-variant	="outline-primary"
						buttons 		
						html-field		="labelfield" 
						id				="radio-group-2" 
						v-model			="myStyle.labelColor" 
						:options		="colorOptions" 
						:disabled 		= "!myStyle.isLabeled" 
					> 
					</b-form-radio-group> 
    			</b-form-group>
				<b-form-checkbox id="checkIsLabeled" v-model="myStyle.isLabeled" >labels aan</b-form-checkbox> 
 				<b-form-group class="d-lg-none" sm label="label kleur">
					<b-form-select size="sm" :disabled = " ! myStyle.isLabeled" html-field="labelfield" id="select-group-line-color" v-model="myStyle.labelColor" :options="colorOptions" > </b-form-select>
    			</b-form-group>
				<b-form-group sm label="label item"  v-if=" isVector "  >
					<b-form-select v-model="myStyle.labelField" :disabled='! myStyle.isLabeled' :options="labelFieldOptions" size="sm" ></b-form-select>
				</b-form-group>
 				<b-form-group sm label="uitlijning" >
					<b-form-select v-model="myStyle.textAlign" :disabled='! myStyle.isLabeled' :options="textAlignOptions" size="sm"> </b-form-select>
					<b-form-select v-model="myStyle.labelYOffset" :disabled='! myStyle.isLabeled' :options="textYOffsetOptions" size="sm"> </b-form-select>
				</b-form-group>
				<b-form-checkbox id="checkIsANumber" v-model="myStyle.isANumber" :disabled='! myStyle.isLabeled'>als nummer</b-form-checkbox> 
 				<b-form-group sm label="label grootte" >
					<b-form-spinbutton id="labelFontSize" :disabled='! myStyle.isLabeled' v-model="myStyle.labelFontSize" min="0" max="100"></b-form-spinbutton>
    			</b-form-group>

  			</b-card>
 		   </b-col>
 		   <b-col lg="3">
			<b-card v-if='isVector'  sm >
 				<b-form-group class="d-none d-lg-block" sm label="lijn kleur">
					<b-form-radio-group 
						stacked
						buttons 
						size					="sm"
						id						="radio-group-line-color" 
						button-variant			="outline-primary"
						:disabled 				= "getProperty('strokeWidth') == 0"
						html-field				="labelfield" 
						v-model					="myStyle.strokeColor" 
						:options				="colorOptions" > 
					</b-form-radio-group> 
    			</b-form-group>
 				<b-form-group class="d-lg-none" sm label="lijn kleur">
					<b-form-select size="sm" :disabled = " myStyle.strokeWidth == 0 " html-field="labelfield" id="select-group-line-color" v-model="myStyle.strokeColor" :options="colorOptions" > </b-form-select>
    			</b-form-group>
 				<b-form-group sm label="lijn dikte" >
					<b-form-spinbutton id="strokeWidthspinbut" v-model="myStyle.strokeWidth" min="0" max="100"></b-form-spinbutton>
    			</b-form-group>
 				<b-form-group sm label="lijn stijl" >
					<b-form-select 
						v-model="myStyle.strokeDashstyle"  :options="strokeDashStyleOptions" size="sm">
					</b-form-select>
    			</b-form-group>
  			</b-card>
 		   </b-col>

 		   <b-col lg="3">
			<b-card v-if='isVector'  sm >
 				<b-form-group  class="d-none d-lg-block" sm label="vulling kleur">
					<b-form-radio-group 
						stacked
						buttons 
						button-variant		="outline-primary"
						size				="sm"
						:disabled 			= "!myStyle.showFills"
						html-field			="labelfield" 
						id					="radio-group-fill-color" 
						v-model				="myStyle.fillColor" 
						:options			="colorOptions" > 
					</b-form-radio-group> 
 				</b-form-group>
 				<b-form-group  id="freeColor" sm label="vulling kleur" :disabled = " !myStyle.showFills">
 					<b-form-input v-model = "myStyle.fillColor" type="color"></b-form-input>
    			</b-form-group>
 				<b-form-group  class="d-lg-none" sm label="vulling kleur">
					<b-form-select size="sm" :disabled = " !myStyle.showFills" html-field="labelfield" id="select-group-fill-color" v-model="myStyle.fillColor" :options="colorOptions" > </b-form-select>
    			</b-form-group>
				<b-form-checkbox :disabled="  (editMode == 'feature' && myObject.getGeometry().getType() == 'LineString')  "  id="checkIsFilled" v-model="myStyle.showFills" >vulling aan</b-form-checkbox> 
				<b-form-checkbox id="checkStripes" 			:disabled = " !myStyle.showFills" 			v-model="myStyle.patternHorStripes" >gestreept</b-form-checkbox> 
				<b-form-checkbox id="checkStripesDashed" 	:disabled = " !myStyle.patternHorStripes" 	v-model="myStyle.patternHorStripesDashed" >gestreept-onderbroken</b-form-checkbox> 
 				<b-form-group v-if=" editMode == 'layer'" label-cols-sm="5"  sm label="vulling matheid" >
					<b-form-spinbutton id="fillOpacitySpinButton" v-model="myStyle.fillOpacity" min="0" max="1" step="0.1"></b-form-spinbutton>
    			</b-form-group>
  			</b-card>
 		  </b-col>
	
		  </b-row> <!-- row -->

		</b-container>

    </b-modal>
	<modal-list-features></modal-list-features>
	<modal-download-geojson-data></modal-download-geojson-data>
	<modal-download-gml-data></modal-download-gml-data>
	<modal-download-gpx-data></modal-download-gpx-data>
	<modal-download-shape-data></modal-download-shape-data>
	<modal-legend-graphic ref="modalLegendGraphic"></modal-legend-graphic>
</div>

</template>

<script>


import ModalListFeatures 				from './modal_list_features.vue';
import ModalDownloadGeojsonData 		from './modal_download_geojson_data.vue';
import ModalDownloadGmlData 			from './modal_download_gml_data.vue';
import ModalDownloadGpxData 			from './modal_download_gpx_data.vue';
import ModalDownloadShapeData 			from './modal_download_shape_data.vue';
import ModalLegendGraphic 				from './modal_legend_graphic.vue';
import {catchJSONParse} 				from './globals.js';
import {kadasterKLICFeatureTypes}		from './globals.js';
import {kadasterKLICfeatureNameSpaces}	from './globals.js';
import {setFeatureStateUpdate}  		from './globals.js';
import {copyFeaturesToLayer}			from './drawing_tools.vue';
import {saveFeatures}   		        from './wfsFunctions.js';
import Feature 							from 'ol/Feature';
import {Point} 							from 'ol/geom';
import {getDefaultVectorStyle} 			from './styleFunctions.js';

import GML32            				from 'ol/format/GML32';
import GeoJSON 							from 'ol/format/GeoJSON';


export default {
	data() {
		return {
			isShown				: false,
			canBecomeOwner		: false,
			myObject			: null,
			editMode			: 'layer',
			isVector			: true,
			isEdited			: false,
			wmsLayers			: [],
			update				: 0,
			labelFieldOptions	: [],
			colorOptions		: [ 
					{ labelfield:"<span class='colorbox' style='float: left; background:black'>	</span>zwart", name: 'zwart', value	: "black" },
					{ labelfield:"<span class='colorbox' style='float: left; background:grey'>	</span>grijs", name: 'grijs', value	: "grey" },
					{ labelfield:"<span class='colorbox' style='float: left; background:white'>	</span>wit", name: 'wit', value	: "white" },
					{ labelfield:"<span class='colorbox' style='float: left; background:red'>	</span>rood", name: 'rood', value	: "red" },
					{ labelfield:"<span class='colorbox' style='float: left; background:yellow'>	</span>geel", name: 'geel', value	: "yellow" },
					{ labelfield:"<span class='colorbox' style='float: left; background:green'>	</span>groen", name: 'groen', value	: "green" },
					{ labelfield:"<span class='colorbox' style='float: left; background:brown'>	</span>bruin", name: 'bruin', value	: "brown" },
					{ labelfield:"<span class='colorbox' style='float: left; background:blue'>	</span>blauw", name: 'blauw', value	: "blue" },
					{ labelfield:"<span class='colorbox' style='float: left; background:magenta'></span>magenta", name: 'magenta', value	: "magenta" }
			],
			pointTypeOptions	: [
          			{ value: 'circle', 			text: 'cirkel' },
          			{ value: 'cross', 			text: 'kruis' },
          			{ value: 'triangle',		text: 'driehoek' },
          			{ value: 'square',			text: 'vierkant' },
          			{ value: 'arrow',			text: 'pijl' },
			],
			textYOffsetOptions	: [
          			{ value: -10, 				text: 'boven' },
          			{ value:  10, 			    text: 'onder' },
			],
			textAlignOptions	: [
          			{ value: 'left', 			text: 'links' },
          			{ value: 'right', 			text: 'rechts' },
			],
			strokeDashStyleOptions	: [
          			{ value: 'solid', 			text: 'getrokken' },
          			{ value: 'dash', 			text: 'gestreept' },
          			{ value: 'dashdot', 		text: 'streep-punt' },
          			{ value: 'longdash', 		text: 'lange streep' },
          			{ value: 'longdashdot', 	text: 'Lstreep-punt' },
        	],
			myStyle				: {
					layerOpacity: 	null,
					timeBP		: 	null,
					labelColor	:	null,
					fillColor	:	null,
					fillOpacity	:	null,
					showFills	:  	null,
					patternHorStripes	:  	null,
					strokeColor	: 	null,
					strokeWidth	: 	null,
					isLabeled	: 	null,
					pointRadius	: 	null,
					pointAngle	: 	null,
					pointType	: 	null,
					labelField	:  	null,
					isANumber	:  	false,
					labelFontSize	:  	false,
					strokeDashstyle		: null
			}
		}
	},
	methods: {
		show: function ( myObject, mode ) {
			var a = Object.assign(this.$data, this.$options.data.call(this)); //reset data to inital values
			this.myObject = myObject;
			this.isVector = false;
			this.editMode = 'layer';
			if ( mode == "featureMode" ) {
				this.editMode = "feature"
				this.isVector = true
			} else {
				if (this.myObject.get('isEdited')) {
					this.isEdited = true;
				}
			}

			let props = this.myObject.get('style') || {}
			if ( this.editMode == "feature" ) {
				props = catchJSONParse( this.myObject.get('style') ) 
				this.isVector = true
				this.setLabelFieldOptions( [ this.myObject ] )
			}

			for (const [key, value] of Object.entries( props )) {
  				this.myStyle[ key ] = value;
			}
			
			if ( this.editMode == "layer" ) {
				this.myStyle.layerOpacity = this.myObject.getOpacity();
				let layerType = myObject.get('type')
				if ( layerType == 'wms' ) {
					this.wmsLayers = myObject.getSource().getParams()["LAYERS"].split(",")
				} else if ( layerType == 'user' || layerType == 'wfs' ) {
					this.isVector = true
					this.setLabelFieldOptions( myObject.getSource().getFeatures() )
				}
			}
			this.canBecomeOwner = map.get('app').userdata.groups[0] == 'bva' && (myObject.get('type') == 'user' || myObject.get('type') == 'image' )
            this.isShown 	= true
        },
		setLabelFieldOptions: function ( features ) {
			let options 	= {};
			for ( let j = 0; j < features.length; j++ ){
				let feature = features[j];
				let keys = feature.getKeys();
				for ( let i = 0 ; i < keys.length; i ++ ) {
					let key = keys[i];
					if ( ! ( key in options) && key != feature.getGeometryName() ){
						options[ key ] = feature.get( key );
					}
				}
			}
			//convert object with props to list of objects
			this.labelFieldOptions = Object.keys( options )
			this.labelFieldOptions.splice(0, 0, 'none')
		},
		reload: function () {
			this.myObject.getSource().refresh();
 			this.myObject.set('isModified', false );
		},
		zoom: function () {
            this.isShown = false;
            if ( this.myObject.getSource().getExtent && this.myObject.getSource().getFeatures().length) {
                map.getView().fit( this.myObject.getSource().getExtent(), { minResolution: 0.21 } ) ;
            } else if ( this.myObject.get('type') == 'image' ) {
				let specs = this.myObject.get('layerspecs')
                map.getView().fit( [ specs.leftextent, specs.bottomextent, specs.rightextent, specs.topextent], { minResolution: 0.21 } ) ;
			}
        },
		setProperty( key, val ) {
			if ( this.myObject != null ) {
				if ( key == 'layerOpacity' ) {
					this.myObject.setOpacity( val );
				} else {
					this.myObject.set( key, val ) ;
					this.myObject.changed();
				}
			}
		},
		getProperty( key ) {
			if ( this.myObject != null ) {
				if ( key == 'layerOpacity' ) {
					return this.myObject.getOpacity();
				} else {
					let v =  this.myObject.get( key ) 
					if ( typeof ( v ) === 'undefined' ) {
					} else {
						return v;
					}
				}
			} else {
				return '';
			}
		},
		showLegend( wmsLayer ) {
				let legendURLParams = {
					BBOX: 	map.getView().calculateExtent( map.getSize()),
					CRS:  	map.getView().getProjection().getCode(),
					WIDTH:	100,
					HEIGHT:	100,
					LAYER:	wmsLayer
				}
				let sld = this.myObject.get('layerspecs')['sldurl']
				if ( sld != null && sld != '') {
					legendURLParams['sld'] = sld 
				}
				var url = this.myObject.getSource().getLegendUrl( 1, legendURLParams )
				this.$refs['modalLegendGraphic'].url = url
				this.$bvModal.show('modal-legend-graphic')
		},
		saveStyle: function() {
			let newPropsObj = {}
			if ( this.editMode == "layer" ) {
				this.myObject.setOpacity( this.myStyle.layerOpacity );
				if ( this.myObject.get('id') == 29164 ) {
					this.myObject.set('timeBP', this.myStyle.timeBP)
					let params = this.myObject.get('source').getParams()
					params['timeBP'] = this.myStyle.timeBP
					this.myObject.get('source').updateParams( params ) 
				}
				this.myObject.set( 'style', Object.assign( newPropsObj, this.myStyle ) )
			} else {
				this.myObject.set( 'style', JSON.stringify( Object.assign( newPropsObj, this.myStyle ) ))
			}
			this.myObject.changed()
		},
		updateWMSParams: function() {
					this.myObject.set('timeBP', this.myStyle.timeBP)
					let params = this.myObject.get('source').getParams()
					params['timeBP'] = this.myStyle.timeBP
					this.myObject.get('source').updateParams( params ) 
		},
		resetFeaturesToDefaultStyle: function () {
			let features = this.myObject.getSource().getFeatures();
			for(let i = 0; i < features.length; i++) {
				features[i].set('style',null);
				features[i].changed();
				setFeatureStateUpdate( features[i] )
			}
			if ( features.length > 0) {
				this.myObject.set('isModified',true) 
			}
		},
		resetToDefaultLayerStyle: function() {
			let defaultStyle = getDefaultVectorStyle();	
			for (const [key, value] of Object.entries( defaultStyle )) {
            	this.myStyle[ key ] = value;
            }
		},
		becomeOwner: function() {
       	 	fetch('changeOwner.php?', {
				method: 'post',
  				headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify( {
                    layerid: this.myObject.get('id')      
				}) 
			})
			.then( response => response.json())
			.then( data => this.$bvModal.msgBoxOk( data.state + ". Herlaad nu de laag zodat de (eventuele) veranderingen effect hebben.") )
		},

		submitGISData: function() {
			this.$root.$refs.modalAskfilelocation.ask('Selecteer het bestand met GeoJSON gegevens: velden "label" en "description" worden geïmporteerd; andere niet.')
			.then( result => {
					const fd = new FormData()
					fd.append('upfile', result.file)
					fetch('uploadGISdata.php', {
    					method: 'POST',
    					body: fd
  					})
					.then( response => response.text())
					.then( text => {//the result should be text with geoJSON data
						let geoJSON = new GeoJSON({ 'geometryName': 'the_geom'})
						let data = geoJSON.readFeatures( text )
						copyFeaturesToLayer( data, this.myObject )
						saveFeatures( this.myObject )
						map.get('app').$bvModal.msgBoxOk('De data van zijn toegevoegd (aantal features: '+data.length+').')
						
					})
   					.then(json => console.log(json))
   					.catch(err => console.error(err))
			})
		},

		submitBoreLog: function() {
			this.$root.$refs.modalAskfilelocation.ask('Selecteer het bestand met boor gegevens:')
			.then( result => {
					const fd = new FormData()
					fd.append('borelog', result.file)
					fetch('uploadBoorXML.php', {
    					method: 'POST',
    					body: fd
  					})
					.then( response => response.text())
					.then( text => {//the result should be an array of pointsurvey meta data, with id, x, y and antropadmix
						let data = JSON.parse( text )
						this.$root.$refs.modalSelect.ask('Selecteer de data uit de borelog die je wilt weergeven.', data[0] )
						.then( result => {
							let features = []
							for( let i = 0; i < data.length; i++) {
								let item = data[i]
								let feature = createFeature( item.X, item.Y, item.id, item[ result ] )
								features.push( feature )
							}
							copyFeaturesToLayer( features, this.myObject )
							saveFeatures( this.myObject )
							map.get('app').$bvModal.msgBoxOk('De meta data van de boorgegevens zijn toegevoegd.')
						})
					})
   					.then(json => console.log(json))
   					.catch(err => console.error(err))
			})
		},

		submitKlicUrl: function() {
			this.$root.$refs.modalQuestion.ask('Geef de URL naar de KLIC data:')
			.then( url => {
				if (url.startsWith('https://service10.kadaster.nl')) {
					var format = new GML32({ 
						srsName: 		'28992',
						featureNS: 		kadasterKLICfeatureNameSpaces, 
						featureType:  	kadasterKLICFeatureTypes
					})
					fetch( 'getKLIC.php?url=' + url )
					.then( response => response.text())
					.then( text => {
         				var features = format.readFeatures( text )

						let parser = new DOMParser();
						let xmlDoc = parser.parseFromString( text ,"text/xml");
						var linkElements = xmlDoc.getElementsByTagNameNS('*', 'link')
						var annotatieElements = xmlDoc.getElementsByTagNameNS('*', 'Annotatie')
						var maatvoeringElements = xmlDoc.getElementsByTagNameNS('*', 'Maatvoering')
						var lokaalIdElements = xmlDoc.getElementsByTagNameNS('*', 'lokaalID')

						let i = features.length;
						while( i-- ) { //iterate backwards so we can remove during iterating
							let feature = features[i]
							if ( feature.getGeometry() == undefined )  {
								features.splice( i, 1);
								continue
							}
							let localName = "unknown"
							let id = feature.getId()
							let idNumber = id.substring( id.lastIndexOf('-') + 1 )
							for ( let k = 0; k < linkElements.length; k++ ) {
								let linkElement = linkElements[k] 
								let xlink = linkElement.getAttributeNS('http://www.w3.org/1999/xlink', 'href') 
								let xlinkNumber = xlink.substring( xlink.lastIndexOf('-') + 1 )
								if ( xlinkNumber == idNumber ) {
									localName = linkElement.parentElement.localName.toLowerCase()
									break;
								}
							}
							if ( localName == 'unknown' ) {
								for ( let k = 0; k < lokaalIdElements.length; k++ ) {
									let lokaalIdElement = lokaalIdElements[k] 
									let imklNode = lokaalIdElement.parentNode.parentNode.parentNode
									let lokaalIdId = imklNode.getAttribute('gml:id')
									if ( lokaalIdId == id || imklNode.nodeName == 'imkl:Maatvoering' ) {
										features.splice( i, 1 )
										break;
									}
								}
								for ( let k = 0; k < maatvoeringElements.length; k++ ) {
									let maatvoeringElement = maatvoeringElements[k] 
									let maatvoeringId = maatvoeringElement.getAttribute('gml:id') 
									if ( maatvoeringId == id ) {
										features.splice( i, 1 )
										break;
									}
								}

							}

							let strokeColor = 'black'
							let strokeWidth = 3
							let showFills = false
							let fillColor = 'grey' 
							if ( localName == 'mantelbuis' ) {
								strokeColor = 'black'
							} else if ( localName == 'waterleiding' ) {
								strokeColor = 'blue'
							} else if ( localName == 'thermischepijpleiding' ) {
								strokeColor = 'darkblue' 
							} else if ( localName == 'oliegaschemicalienpijpleiding' ) {
								strokeColor = 'yellow'
							} else if ( localName == 'elektriciteitskabel' ) {
								strokeColor = 'red'
							} else if ( localName == 'rioolleiding' ) {
								strokeColor = 'magenta'
							} else if ( localName == 'telecommunicatiekabel' ) {
								strokeColor = 'green'
							} else if ( localName == 'kabelbed' ) {
								strokeColor = 'green'
							}  else if ( localName == 'annotatie' ) {
								features.splice( i, 1);
								continue
							}
							if ( typeof feature.getGeometry() !== "undefined" ) {
								if ( feature.getGeometry().getType() == 'Polygon' && ! id.includes('Graafpolygoon' ) ) {
									showFills = true
								}
							}
							feature.set( 'style', JSON.stringify( { 'strokeColor' : strokeColor, 'strokeWidth' : strokeWidth, 'showFills' : showFills, 'fillColor' : fillColor} ) )
							feature.set( 'label', localName);
						}
						copyFeaturesToLayer( features, this.myObject )
						saveFeatures( this.myObject )
						map.get('app').$bvModal.msgBoxOk('De KLIC laag is opgevraagd en toegevoegd.')
					})
				}
			})
		}
	},

  	components: { ModalListFeatures, ModalDownloadGeojsonData, ModalDownloadGmlData, ModalDownloadGpxData, ModalDownloadShapeData, ModalLegendGraphic }
}

function createFeature( X, Y, label, description ) {
	let point = new Point([ X, Y ])
	let feature = new Feature ( { 'the_geom': point}) 
	feature.setGeometryName('the_geom')
	feature.set ( 'label' , label )
	feature.set ( 'description' , description )
	return feature
}

</script>

<style>
	.colorbox {
		width:	10px;
		height:	10px;
		margin:	5px;
		display:inline-block;
		border:	1px solid;
	}
</style>


